import axios from "axios";
import jwt_decode from "jwt-decode";
// import { commit } from "vuex-pathify";
import accountApi from "adready-api/api/account";
import {
  ACCOUNT_FLAG_MNI,
  ACCOUNT_MNI_PREFIX_MERD,
} from "adready-api/constant";
import { userRolesApi } from "adready-api/api/user";
import commonHelper from "adready-api/helpers/common";
import store from "./../../store";
import config from "../../config";

const getDefaultUser = () => ({
  email: null,
  id: null,
  username: null,
  first_name: null,
  last_name: null,
});

const getDefaultState = () => ({
  user: getDefaultUser(),
  currentAdreadyUserId: null,
  accounts: [],
  userRoles: [],
  userAccountRoles: [],
  exp: 0,
  iat: 0,
  token: null,
  accessToken: null, // adready access token for plus user
});

const state = getDefaultState();
const getters = {
  //TODO::check for valid token as well as null
  isAuthenticated: (state) =>
    state.token !== null &&
    state.exp >= Math.floor(new Date().getTime() / 1000),
  isTokenExpired: (state) =>
    state.exp >= Math.floor(new Date().getTime() / 1000),

  token: (state) => state.token,
};
const mutations = {
  SET_ADREADY_ACCOUNT(state, account) {
    store.state.adreadyAccount = account;
  },
  SET_USER_ACCOUNTS(state, userAccounts) {
    store.state.userAccounts = userAccounts;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_ADREADY_USER_ID(state, userId) {
    state.currentAdreadyUserId = userId;
  },
  SET_USER_ROLES(state, userRoles) {
    state.userRoles = userRoles;
  },
  SET_USER_ACCOUNT_ROLES(state, userAccountRoles) {
    state.userAccountRoles = userAccountRoles;
  },
  SET_ACCOUNTS(state, accounts) {
    state.accounts = accounts;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_ACCESS_TOKEN(state, token) {
    state.accessToken = token;
  },
  SET_PROP(state, { prop, value }) {
    state[prop] = value;
  },
  SET_USER_PROP(state, { prop, value }) {
    state.user[prop] = value;
  },
  RESET_TOKEN(state) {
    state.token = null;
  },
  RESET_ACCESS_TOKEN(state) {
    state.accessToken = null;
  },
  RESET_USER(state) {
    state.user = getDefaultUser();
  },
  RESET_ADREADY_USER_ID(state) {
    state.currentAdreadyUserId = null;
  },
  RESET_USER_ROLES(state) {
    state.userRoles = [];
  },
  RESET_USER_ACCOUNT_ROLES(state) {
    state.userAccountRoles = [];
  },
};

const actions = {
  async login({ dispatch, commit }, login) {
    await axios({
      method: "post",
      url: `${config.API_URL}/login`,
      data: {
        email: login.email,
        password: login.password,
      },
    })
      .then((response) => {
        const token = response.data.response.data.token;
        dispatch("decodeToken", token).then(() => {
          //authenticate with adready and set the adready access token
          dispatch("setAdreadyAccessToken", token);
        });
      })
      .catch((error) => {
        commit(
          "SET_ERRORS",
          [error.response.data.response.message],
          { root: true }
        );
      });
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      commit("RESET_USER");
      commit("RESET_ADREADY_USER_ID");
      commit("RESET_USER_ROLES");
      commit("RESET_TOKEN");
      commit("RESET_ACCESS_TOKEN");
      commit("RESET_USER_ACCOUNT_ROLES");
      commit("RESET_NOTIFICATION", null, {
        root: true,
      });
      resolve();
    });
  },

  setAdreadyAccessToken({ commit, state }, token) {
    return axios({
      method: "post",
      withCredentials: true,
      url: config.ADREADY_URL + "/api/auth/plus_login",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const accessToken = res.data.result.accessToken;
        commit("SET_ACCESS_TOKEN", accessToken);
        if (accessToken) {
          let decodedAccessTokenAdready = jwt_decode(accessToken);
          const userId = decodedAccessTokenAdready.sub;
          commit("SET_ADREADY_USER_ID", userId);

          // load adready account obj

          // get current AR+ account ID
          // this is a hack which lets us manually set the account ID to use
          // until we have a proper account-switcher in the AR+ UI
          const overridePlusAccountId = localStorage.getItem(
            "override_plus_account_id"
          );

          let plusAccountId;
          if (overridePlusAccountId) {
            plusAccountId = parseInt(overridePlusAccountId, 10);
          }
          if (!plusAccountId || Number.isNaN(plusAccountId)) {
            plusAccountId = state.accounts[0].id;
          }
          const userAccountPromise = accountApi.userAccount(userId);
          const userAccountRolesPromise = accountApi.userAccountRoles(userId);
          const adreadyAccountPromise = accountApi.account(plusAccountId, {
            adreadyPlus: true,
          });
          const globalRolesPromise = userRolesApi.globalRoles(userId);

          Promise.all([
            userAccountPromise,
            userAccountRolesPromise,
            adreadyAccountPromise,
            globalRolesPromise,
          ])
            .then((responses) => {
              const accounts = responses[0].map((a) => {
                if (a.hasFlag(ACCOUNT_FLAG_MNI)) {
                  a.name = `${ACCOUNT_MNI_PREFIX_MERD} - ${a.name}`;
                  return a;
                }
                return a;
              });
              const sortedAccounts = commonHelper.caseInsensitiveSort(
                accounts,
                "name"
              );
              commit("SET_USER_ACCOUNTS", sortedAccounts);
              commit("SET_USER_ACCOUNT_ROLES", responses[1]);
              commit("SET_ADREADY_ACCOUNT", responses[2]);
              commit("SET_USER_ROLES", responses[3].data);
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((e) => {
        console.warn("Failed to cross-authenticate with AdReady", e);
      });
  },

  decodeToken({ commit, getters }, token) {
    return new Promise((resolve, reject) => {
      if (getters.isAuthenticated) return resolve();
      if (token === null) return reject();
      let decoded = jwt_decode(token);
      commit("SET_TOKEN", token);
      commit("SET_USER", decoded.user);
      commit("SET_ACCOUNTS", decoded.accounts);
      commit("SET_PROP", { prop: "iat", value: decoded.iat });
      commit("SET_PROP", { prop: "exp", value: decoded.exp });
      localStorage.setItem("token", token);
      resolve();
    });
  },
  async forgotPassword({ commit }, email) {
    await axios({
      method: "post",
      url: `${config.API_URL}/password/forgot`,
      data: {
        email: email,
      },
    })
      .then((response) => {
        commit("SET_MESSAGES", [response.data.message], { root: true });
      })
      .catch((error) => {
        console.error(error);
        commit("SET_ERRORS", ["Some technical error occured, please contact support"], {
          root: true,
        });
      });
  },
  async resetPassword({ commit }, resetData) {
    await axios({
      method: "post",
      url: `${config.API_URL}/password/reset`,
      data: resetData,
    })
      .then((response) => {
        commit("SET_MESSAGES", response.data.messages, { root: true });
      })
      .catch((error) => {
        console.error(error);
        commit("SET_ERRORS", ["Some technical error occured, please contact support"], {
          root: true,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
