import { make } from "vuex-pathify";

const state = {
  allKpiOptions: null,
  targetingElements: null,
  productCategories: [],
  productHierarchy: null
};
const actions = {};

const mutations = {
  ...make.mutations(state)
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
