import Vue from "vue";
import Vuex from "vuex";
import authentication from "./modules/authentication";
import common from "./modules/common";
import axios from "axios";
import VuexReset from "@ianwalter/vuex-reset";
import pathify from "./../store-pathify";
import config from "../config";

Vue.use(Vuex);
const getDefaultNotification = () => ({
  messages: [],
  title: "",
  style: "success", //can be success, info, alert
  show: false,
});
const getDefaultState = () => ({
  view: {
    notification: getDefaultNotification(),
  },
  adreadyAccount: null,
  rateCard: null,
  showLoading: false,
  reporting_urls: [{ type: null, url: null }],
  advisr_url: "",
  providers: [],
  segments: [],
  whitelist: [],
  userAccounts: [],
  productHierarchy: null,
});
export default new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    SET_MESSAGES(state,messages) {
      messages.map(message => {
        Vue.notify({
          title: "Success!",
          text: message,
          type: "success"
        });
      });
    },
    SET_ERRORS(state,errors) {
      errors.map(error => {
        Vue.notify({
          title: "Error",
          text: error,
          type: "error"
        });
      });
    },
    RESET_NOTIFICATION(state) {
      state.view.notification = getDefaultNotification();
    },
    SET_NOTIFICATION_PROP(state, { prop, value }) {
      state.view.notification[prop] = value;
    },
    SET_SHOW_LOADING(state, isLoading) {
      state.showLoading = isLoading;
    },
    SET_REPORTING_URLS(state, urls) {
      state.reporting_urls = urls;
    },
    SET_ADVISR_URL(state, url) {
      state.advisr_url = url;
    },
    SET_PROVIDERS(state, providers) {
      state.providers = providers;
    },
    SET_SEGMENTS(state, segments) {
      state.segments = segments;
    },
    SET_WHITELIST(state, whitelist) {
      state.whitelist = whitelist;
    },
    SET_RATECARD(state, ratecard) {
      state.rateCard = ratecard;
    },
  },
  actions: {
    async getReports({ commit, rootState }) {
      const authenticationState = rootState.authentication;

      return await axios({
        method: "get",
        url: `${config.API_URL}/reports/account/${authenticationState.accounts[0].id}`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }).then((response) => {
        let urls = response.data.map((report) => ({
          url: report.url,
          type: report.type,
          height: report.height,
        }));

        commit("SET_REPORTING_URLS", urls);
      });
    },

    async advisrAuthentication({ commit, rootState }) {
      const authenticationState = rootState.authentication;

      await axios({
        method: "post",
        url: `${config.API_URL}/authenticate`,
        data: {
          email: authenticationState.user.email,
        },
      })
        .then((response) => {
          commit("SET_ADVISR_URL", response.data.url);
        })
        .catch((error) =>
          commit(
            "SET_ERRORS",[error.response.data.error],
            { root: true }
          )
        );
    },
    async getProviders({ commit }) {
      await axios({
        method: "get",
        url: `${config.API_URL}/segments/providers`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
        .then((response) => {
          commit("SET_PROVIDERS", response.data);
        })
        .catch((err) => err);
    },
    async getSegments(
      { commit },
      { query = "", size = 50, offset = 0, provider_id = null }
    ) {
      await axios({
        method: "get",
        url: `${config.API_URL}/segments/search`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { query, size, offset, provider_id },
      })
        .then((response) => {
          commit("SET_SEGMENTS", response.data);
        })
        .catch((err) => err);
    },
    async getPMPAllowList({ commit }) {
      await axios({
        method: "get",
        url: `${config.API_URL}/pmp-whitelist`,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
        .then((response) => {
          commit("SET_WHITELIST", response.data);
        })
        .catch((err) => err);
    },
  },
  modules: { authentication, common },
  plugins: [pathify.plugin, VuexReset()],
  strict: process.env.NODE_ENV !== "production",
});
